<template>
  <v-container fluid>
    <page-title :title="$t('gallery.gallery')">
      <v-col class="mt-4" cols="4" md="1">
        <language-selection />
      </v-col>
    </page-title>
    <list-items :items="galleryList"></list-items>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'GalleryList',
  metaInfo: {
    title: 'Галерея | MetaMorfosi',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Художник, фотограф, психолог и арт-терапевт, автор проекта «Metamorfosi». В галерее вы можете найти и приобрести графические и живописные работы Надежды  Навроцкой'
      }
    ]
  },
  components: {
    LanguageSelection: () => import('../../components/shared/LanguageSelection'),
    ListItems: () => import('@/components/shared/lists/ListItems'),
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    ...mapState({
      loading: state => state.shared.loading
    }),
    i18n() {
      return this.$i18n.locale
    },
    galleryListFilterLanguage() {
      return this.$store.getters
        .galleryListFilterLanguage(this.i18n)
        .filter(gallery => !gallery.draft)
    },
    galleryList() {
      return this.galleryListFilterLanguage.map(item => ({
        id: item.galleryTitle,
        imageSrc: item.imageSrc,
        path: `/galleryCustomers/${item.galleryTitle}`,
        title: item.galleryName,
        shortDescription: item.shortDescription
      }))
    }
  },
  created() {
    if (this.galleryListFilterLanguage.length === 0) {
      this.$store.dispatch('getGalleryList')
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
